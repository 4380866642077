<template>
  <div id="app">
    <el-header class="header-bg bgclor" style="height: 80px;width:100%;position:absolute;top:0px">
      <show-header></show-header>
    </el-header>

    <div class="container">
      <!-- CRT 图片缩放按钮 -->
      <!-- <div class="btns" v-if="crtId!=''">
        <span class="iconfont" @click="aaa">&#xe602;</span>
        <span class="iconfont" @click="bbb">&#xe601;</span>
      </div> -->

      <dv-border-box-3 class="container-left" :color="['#07a5ae', '#07a5ae']">
        <div style="height:100%;overflow:scroll;">
          <el-input type="text" placeholder="输入关键字进行搜索" v-model="filterText"
            style="margin:10px 10px 0;width:93%;height:35px;outline:none;background:rgba(0,0,0,0.2);color:#fff;border:2px solid #019da5;border-radius:5px"></el-input>
          <div>
            <el-select v-model="sortVal" placeholder="请选择排序方式"
              style="border:2px solid #019da5;border-radius:5px;margin: 5px 0 0 10px;height:35px;line-height:35px;width:65%">
              <el-option v-for="item in sortList" :key="item.value" :label="item.name" :value="item.value">
              </el-option>
            </el-select>
            <span class="sort" @click="searchSort">查询</span>
          </div>
          <!-- 侧边公司\CRT展示 -->
          <el-tree :filter-node-method="filterNode" ref="tree" :default-expand-all="false" :data="crtInfoOptions"
            :props="defaultProps" @node-click="handleNodeClick"></el-tree>
        </div>
      </dv-border-box-3>

      <dv-border-box-10 class="container-center" :color="['#07a5ae', '#07a5ae']">
        <!-- crt图片展示 -->
        <div class="crt-continer" v-drag>
          <div id="overburden" @wheel.prevent="bbimg">
            <img v-for="item in crtDeviceCoorcrtDinateList" :id="item.deviceInfoId" :src="baseUrl + item.iconUrl"
              :style="{ 'left': (parseFloat(item.coordinatex) * crtWid - parseFloat(item.iconWidth) / 2).toString() + 'px', 'top': (parseFloat(item.coordinatey) * crtHei - parseFloat(item.iconWidth) / 2).toString() + 'px', 'width': item.iconWidth + 'px', 'position': 'absolute', 'z-index': '2', 'cursor': 'pointer' }"
              @click="showDeviceInfo(item)" />
            <img v-if="crtId != ''" class="crt-bg" ref="crt" :src="baseUrl + crtUrl" />
          </div>
        </div>
        <div v-if="crtId == ''" class="tips">请选择CRT...</div>
      </dv-border-box-10>

      <dv-border-box-10 class="container-right" :color="['#07a5ae', '#07a5ae']">
        <!-- crt设备点位展示 -->
        <div style="height:10%">
          <el-row class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-input icon="search" v-model="addedDeviceInfoQuery.id" placeholder="输入 < 设备编号 > 进行查找" size="normal"
                @input="getAddedDeviceInfoList" clearable>
                <template slot="prepend"><i class="el-icon-search"></i></template>
              </el-input>
            </el-col>
          </el-row>
          <el-row class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-input v-model="addedDeviceInfoQuery.typeName" placeholder="输入 < 设备类型 > 进行查找" size="normal"
                @input="getAddedDeviceInfoList" clearable>
                <template slot="prepend"><i class="el-icon-search"></i></template>
              </el-input>
            </el-col>
          </el-row>
        </div>
        <div style="height:80%">
          <el-row :gutter="0" class="container-right-item">
            <el-col :span="24" :offset="0">
              <el-table :data="addedDeviceInfoList" height="100%" highlight-current-row>
                <el-table-column prop="id" label="设备编号" width="160" align="center"></el-table-column>
                <el-table-column prop="typeName" label="设备类型" width="80" align="center"></el-table-column>
                <el-table-column label="操作" width="180" align="center">
                  <template slot-scope="scope">
                    <el-button @click="handlePosition(scope.row.id)" @blur="recoveryPosition(scope.row.id)" type="success"
                      size="mini">定位</el-button>
                    <el-button @click="recoveryPosition(scope.row.id)" type="danger" size="mini">取消</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-col>
          </el-row>
        </div>
      </dv-border-box-10>
    </div>

    <!-- 点位信息 -->
    <el-dialog title="设备信息详情" :visible.sync="deviceInfoDialogVisible" width="40%" @close="clearDeviceInfo"
      style="background:rgba(0,0,0,0.5)">
      <div style="height:300px; overflow:scroll;scrollbar-width:none">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="基本信息" name="first">
            <!-- 静态属性 -->
            <div class="staticValBox">
              <span>设备编号：</span>
              <span>{{ deviceInfo.id }}</span>
            </div>
            <div class="staticValBox">
              <span>设备类型：</span>
              <span>{{ deviceInfo.typeName }}</span>
            </div>
            <div class="staticValBox" v-for="item in deviceInfo.device" v-if="item.proName.indexOf('绑定视频') == -1">
              <span>{{ item.proName == "绑定公司" ? "单位" : item.proName }}：</span>
              <span>{{ item.proName == "绑定公司" ? companyNameById[item.value] : item.proName == "系统" ?
                systemNameById[item.value] : item.value }}</span>
            </div>
          </el-tab-pane>
          <!-- 动态属性 -->
          <el-tab-pane label="设备详情" name="second">
            <div v-if="isShowDeviceStatus" class="staticValBox">
              <span>设备状态：</span>
              <span>{{ deviceStatus }}</span>
            </div>
            <div class="staticValBox" v-for="item in deviceValueInfoList" :key="item.id">
              <span
                v-if='item.id.indexOf("报警") == -1 && item.id.indexOf("故障") == -1 && item.id.indexOf("失联") == -1 && item.id.indexOf("设备状态") == -1'>{{
                  item.id }}：</span>
              <span
                v-if='item.id.indexOf("报警") == -1 && item.id.indexOf("故障") == -1 && item.id.indexOf("失联") == -1 && item.id.indexOf("设备状态") == -1'>{{
                  item.value }}</span>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div slot="footer" class="dialog-footer"> 
        <el-button @click="toHistory" type="primary">历史记录</el-button>
        <el-button type="primary" @click="showVideo"
          :type="deviceInfo.videoUrl != null ? 'success' : 'warning'">查看视频</el-button>
      </div>
    </el-dialog>

    <!-- 播放视频弹窗 -->
    <el-dialog title="视频" :visible.sync="playerDialogVisible" :destroy-on-close=true width="1280px" top="7vh"
      style="background:rgba(0,0,0,0.5)">
      <div class="videoClass" style="height:75.5vh;">
        <cus-player ref="video0"></cus-player>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ShowHeader from "@/components/deviceClass/ShowHeader";

import companySystem from "@/api/managementApi/CompanyInfo";
import noticeSysManagement from "@/api/managementApi/NoticeSysManagement";
import crtDeviceCoordinate from "@/api/manageApi/CrtDeviceCoordinate";
import CRT from "@/api/manageApi/CrtInfo";
import deviceInfoManagement from "@/api/manageApi/DeviceInfoManagement.js";
import BaseData from "@/assets/config/BaseData.js";
import drag from "@/../utils/drag.js";
import CusPlayer from '../videos/CusPlayer.vue'  //视频组件

export default {
  components: {
    ShowHeader,
    CusPlayer
  },
  data() {
    return {
      // crt查询
      filterText: "",
      // crt显示
      showCrt: false,
      crtInfoOptions: [],
      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "crtUrl",
      },

      activeName: "first",
      companyNameById: {},
      systemNameById: {},
      //crt信息列表
      crtInfoList: [],
      deviceId: "",
      //当前选中的crt对象
      crtId: "",
      crtUrl: "",

      //点位列表
      crtDeviceCoorcrtDinateList: [],
      addedDeviceInfoList: [],
      //添加点位弹框内模糊查询
      deviceInfoQuery: {
        id: "",
        typeName: "",
      },
      addedDeviceInfoQuery: {
        id: "",
        typeName: "",
        crtPictureId: "",
      },

      //设备信息详情
      deviceInfo: {},

      //当前crt图的宽度
      crtWid: 0,
      //当前crt图的高度
      crtHei: 0,
      //图片虚拟路径前缀，用于访问服务器以获取图片
      baseUrl: BaseData.baseUrl,
      //设备信息详情弹框标志位
      deviceInfoDialogVisible: false,

      //设备动态属性
      deviceValueInfoList: [],

      deviceStatus: "正常", //设备状态
      isShowDeviceStatus: true, //是否显示设备状态

      playerDialogVisible: false, //视频播放器标志位,是否展示视频弹窗
      playUrl:'', //播放地址
      zooms: 100, // 点击缩放

      sortVal: "",
      sortList: [
        {
          name: "时间正序",
          value: "timeAsc",
        },
        {
          name: "时间倒序",
          value: "timeDesc",
        },
        {
          name: "序号正序",
          value: "sortIdAsc",
        },
        {
          name: "序号倒序",
          value: "sortIdDesc",
        },
      ],
    };
  },
  created() {
    this.init();
    this.getCompanyInfo();
    this.jurisSysName();

    this.handleClick();
  },
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },

  computed: {
    dynamicValue(item) {
      //   return item.id
    },
  },
  methods: {
    // 排序方式
    searchSort() {
      if (this.sortVal == "") {
        this.$message.warning("请选择排序方式！");
      } else {
        console.log(this.sortVal);
        CRT.getCrtListByCiIdStructure(this.sortVal).then((res) => {
          this.crtInfoOptions = res.data.data.list;
          console.log(this.crtInfoOptions);
        });
      }
    },
    // 获取所有公司及公司下的crt
    handleClick() {
      // 顶级公司下的CRT
      //   CRT.getCrtByCiId().then(res => {
      // this.crtInfoOptions = res.data.data.list;
      //   })

      // 各个单位下的CRT
      CRT.getCrtListByCiIdStructure().then((res) => {
        this.crtInfoOptions = res.data.data.list;
        console.log(this.crtInfoOptions);
      });
    },

    // 所有公司
    getCompanyInfo() {
      companySystem.getCompanyInfo().then((res) => {
        for (let i in res.data.data.list) {
          this.companyNameById[res.data.data.list[i]["id"]] =
            res.data.data.list[i]["name"];
        }
      });
    },

    // 获取权限系统名称
    jurisSysName() {
      noticeSysManagement.getNotifyBigSystemById().then((res) => {
        this.systemNameById = {};
        this.permissionsSystemName = res.data.data.list;
        for (let i in this.permissionsSystemName) {
          this.systemNameById[this.permissionsSystemName[i].id] =
            this.permissionsSystemName[i].bigSystemName;
        }
      });
    },
    init() {
      this.getCrtInfoList();
    },
    toHistory() {
      this.$router.push({
        path: "/History",
      });
    },
    //视频
    async showVideo() {
      const res = await deviceInfoManagement.getPlayUrlByDeviceId(this.deviceInfo.id)
      if (res.data.data.playUrl) {
        this.playUrl = res.data.data.playUrl;
        //当前页弹窗播放
        if (this.playUrl != "") {
          this.playerDialogVisible = true;
          this.$nextTick(() => {
            console.log(this.$refs["video0"]);
            this.$refs["video0"].createPlayer(this.playUrl, false, "0");
          })
        } else {
          this.$message({
            type: "error",
            message: "获取rtsp地址失败!",
          });
        }
      }
      else if (this.deviceInfo.videoUrl) {
        window.location.href = this.deviceInfo.videoUrl;
      } else {
        //提示信息
        this.$message({
          type: "warning",
          message: "该设备未绑定视频设备！",
        });
      }
    },
    // 條件查询全部已绑定点位的设备信息
    getAddedDeviceInfoList() {
      deviceInfoManagement
        .getAddedDeviceInfoList(this.addedDeviceInfoQuery)
        .then((res) => {
          this.addedDeviceInfoList = res.data.data.list;
          this.updateDeviceColor(res.data.data.list);
        });
    },
    //根据状态改变点位颜色的方法
    updateDeviceColor(deviceInfoList) {
      setTimeout(() => {
        deviceInfoList.forEach((res) => {
            if(res.status == "1"){
              document.getElementById(res.id).classList.add("heart_red");
            }else if(res.status == "2"){
              document.getElementById(res.id).classList.add("heart_yellow");
            }
        });
      }, 500);
    },
    // 定位已绑定点位的设备
    handlePosition(id) {
      var hr = document.getElementsByClassName("heart_red");
      if (hr.length != 0) {
        hr[0].classList.remove("heart_red");
      }
      document.getElementById(id).classList.add("heart_red");
    },
    // 按钮市区焦点时取消定位设备
    recoveryPosition(id) {
      document.getElementById(id).classList.remove("heart_red");
    },

    //查詢全部CRT图片信息
    getCrtInfoList() {
      CRT.getAllCrt().then((res) => {
        this.crtInfoList = res.data.data.list;
        if (this.$route.params.CRTId) {
          this.crtId = this.$route.params.CRTId;
          this.deviceId = this.$route.params.deviceId;
          // recoveryPosition  861854047120116   3YMTPBFNS6X9MS6V111A 86185404712011
          this.handleNodeClick();
          if (this.deviceId != this.CRTId) {
            setTimeout(() => {
              this.handlePosition(this.deviceId);
              //   document.getElementById(this.deviceId).classList.remove("heart_red");
              //   document.getElementById(id).classList.add("heart_red");
            }, 1000);
          }
        }
      });
    },

    //展示点位信息
    showDeviceInfo(item) {
      // TODO 展示点位信息
      deviceInfoManagement.getDeviceInfoById(item.deviceInfoId).then((res) => {
        this.deviceInfo = res.data.data.deviceInfoVo;

        for (const iterator of this.deviceInfo.device) {
          if (iterator.proName == "布防状态") {
            if (iterator.value == 1) {
              iterator.value = "布防";
            } else {
              iterator.value = "撤防";
            }
          }
          if (iterator.proName == "所属系统") {
            iterator.proName = "系统";
          }
        }
      });
      let isLost = false; //判断电信设备是否离线等
      if (item.deviceTypeId.indexOf("DX") != -1) {
        deviceInfoManagement
          .queryAEPDeviceStatus(item.deviceTypeId, item.deviceInfoId)
          .then((res) => {
            if (res.data.data.status == "离线") {
              this.deviceStatus = "离线";
              isLost = true;
            } else if (res.data.data.status == "已注册") {
              this.deviceStatus = "未激活";
              isLost = true;
            } else if (res.data.data.status == "已注销") {
              this.deviceStatus = "已注销";
              isLost = true;
            }
            if (isLost != true) {
              deviceInfoManagement
                .getDeviceInfoValueByDeviceId(item.deviceInfoId)
                .then((res) => {
                  this.deviceStatus = "正常";
                  this.isShowDeviceStatus = true;
                  this.deviceValueInfoList = res.data.data.list;
                  let isShebei = "";
                  for (const i in this.deviceValueInfoList) {
                    if (this.deviceValueInfoList[i]["id"] == "设备状态") {
                      isShebei = this.deviceValueInfoList[i]["value"];
                    }
                    if (
                      this.deviceValueInfoList[i]["id"].indexOf("失联") != -1 ||
                      this.deviceValueInfoList[i]["id"].indexOf("报警") != -1 ||
                      this.deviceValueInfoList[i]["id"].indexOf("故障") != -1 ||
                      this.deviceValueInfoList[i]["id"].indexOf("状态") != -1
                    ) {
                      if (
                        this.deviceValueInfoList[i]["value"] != "正常" &&
                        this.deviceValueInfoList[i]["value"] != "" &&
                        this.deviceValueInfoList[i]["value"] != null
                      ) {
                        this.isShowDeviceStatus = false;
                        // this.deviceStatus = this.deviceValueInfoList[i]["value"]
                      }
                    }
                    if (
                      this.deviceValueInfoList[i]["id"].indexOf("失联") == -1 &&
                      this.deviceValueInfoList[i]["id"].indexOf("报警") == -1 &&
                      this.deviceValueInfoList[i]["id"].indexOf("故障") == -1 &&
                      (this.deviceValueInfoList[i]["value"] == "" ||
                        this.deviceValueInfoList[i]["value"] == null)
                    ) {
                      this.deviceValueInfoList[i]["value"] = "暂无";
                    }
                  }
                  if (isShebei != "" && isShow) {
                    this.deviceStatus = isShebei;
                  }
                });
            }
          });
      }
      this.deviceInfoDialogVisible = true;
    },

    //关闭设备详情的时候清空设备信息详情
    clearDeviceInfo() {
      this.deviceInfo = {};
      setTimeout(() => {
        this.activeName = "first";
      }, 500);
    },

    /************************图片操作************************/
    //缩放
    //图片缩放
    bbimg() {
      var zoom =
        parseInt(document.getElementById("overburden").style.zoom, 10) || 100;
      zoom += event.wheelDelta / 12;
      if (zoom >= 50)
        document.getElementById("overburden").style.zoom = zoom + "%";
      return false;
    },

    aaa() {
      if (this.zooms < 200) {
        this.zooms += 10;
        document.getElementById("overburden").style.zoom = this.zooms + "%";
      }
    },
    bbb() {
      if (this.zooms > 50) {
        this.zooms -= 10;
        document.getElementById("overburden").style.zoom = this.zooms + "%";
      }
    },

    // crt图片改变时的回调函数
    handleNodeClick(data) {
      this.crtDeviceCoorcrtDinateList = [];
      this.addedDeviceInfoList = [];

      //   如果是在CRT点位展示页面查看crt进下面
      if (data) {
        // 拆分crt图片 和 crt路径
        let CRTInfo = data.id.split("/");
        // crtid
        let crtId = CRTInfo[0].split("-")[1];
        // crt路径
        let crtUrl = "crtPicture/" + CRTInfo[1];

        if (data.id.indexOf("crt") != -1) {
          if (crtUrl == undefined) {
            // this.crtId = "";
          } else {
            this.crtUrl = crtUrl;
            this.crtId = crtId;
            this.crtInfoList.forEach((item) => {
              if (item.id == this.crtId) {
                this.crtUrl = item.url;
              }
            });
          }
        } else {
          this.crtId = "";
        }

        if (data.id.indexOf("crtPicture") !== -1) {
          this.processInfo();
        }

        // 如果是从其他地方点crt跳过来走下面
      } else {
        this.crtInfoList.forEach((item) => {
          if (item.id == this.crtId) {
            this.crtUrl = item.url;
          }
        });
        this.processInfo();
      }
    },

    // 处理显示crt点位的方法
    processInfo() {
      this.addedDeviceInfoQuery.crtPictureId = this.crtId;
      setTimeout(() => {
        let crt = this.$refs.crt;
        this.crtWid = crt.width;
        this.crtHei = crt.height;
        this.getCoordinateList();
        this.getAddedDeviceInfoList();
      }, 500);
    },

    //根据当前选中的crt图片id获取点位
    getCoordinateList() {
      crtDeviceCoordinate.getCoordinateByCrtId(this.crtId).then((res) => {
        this.crtDeviceCoorcrtDinateList = res.data.data.list;
      });
    },

    // 筛选crt
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    //返回上一级
    goBack() {
      //   this.$router.push('/Select')
      if (this.$route.params.address == "showPage") {
        this.$router.push("/Select");
        setTimeout(() => {
          this.$router.push("/Show");
        }, 1);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../assets/css/scrollBar";

/deep/.el-dialog__title {
  font-size: 22px;
  font-weight: 600;
  color: #444;
}

/deep/.el-tabs__item {
  font-size: 16px;
}

/deep/.el-dialog__body {
  padding: 10px 20px;
}

.staticValBox {
  width: 100%;
  font-size: 16px;

  span:nth-child(1) {
    display: inline-block;
    width: 35%;
    padding: 10px 10px;
  }
}

// .container{
//     width: 800px;
//     height: 600px;
//     border: 1px solid red;
// }

// ***************************************************************************
// 组件样式
/deep/.el-tree {
  padding: 10px 0;
  user-select: none;
  background: none;
  height: 86vh;
  overflow: scroll;
  box-sizing: border-box;
  scrollbar-width: none;

  .el-tree-node__label {
    font-size: 16px;
    font-weight: 500;
    color: #07d1db;
  }

  .el-tree-node__content {
    height: 35px;
  }
}

// 结构样式
.container {
  margin-top: 30px;

  width: 100%;
  // height: 87vh;
  box-sizing: border-box;
  display: flex;

  .container-left {
    box-sizing: border-box;
    padding: 20px;
    overflow: scroll;
    height: 87vh;
    width: 20%;
  }

  .container-center {
    // width: 58%;
    width: 55%;
    margin: 0 10px;
    height: 87vh;
    overflow: scroll;
    box-sizing: border-box;

    // crt 图片
    .crt-continer {
      position: absolute;
      box-sizing: border-box;

      div {
        box-sizing: border-box;

        .crt-bg {
          width: 1300px;
          cursor: pointer;
        }
      }
    }

    // border: 1px solid red;
  }

  .container-right {
    height: 87vh;
    width: 25%;
    box-sizing: border-box;
    // border: 1px solid red;

    .container-right-item {
      margin: 10px;
    }
  }
}

.tips {
  width: 100%;
  font-size: 38px;
  text-align: center;
  // margin-top: 30%;
  line-height: 70vh;
  letter-spacing: 5px;
  font-weight: 600;
  color: cadetblue;
  user-select: none;
  font-family: "楷体";
}

// el-table 表格 透明样式
/deep/ .el-table,
/deep/ .el-table__expanded-cell {
  background-color: transparent;
}

/* 表格内背景颜色 */
/deep/ .el-table th,
/deep/ .el-table tr,
/deep/ .el-table td {
  background-color: transparent;
}

// /deep/::-webkit-scrollbar {
//   display: none;
// }
/deep/ .el-table th.el-table__cell > .cell {
  font-size: 16px;
  font-weight: 600;
  //   color: rgb(62, 177, 243);
  color: #07d1db;
}

/deep/.el-table__body,
.el-table__footer,
.el-table__header {
  font-size: 16px;
  //   color: rgb(62, 177, 243);
  color: #07d1db;
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border: none;
}

/deep/ th.el-table__cell.is-leaf {
  border: none;
}

/deep/.el-input__inner {
  background: rgba(0, 0, 0, 0);
  color: rgb(242, 245, 247);
  font-size: 16px;
  border: none;
}

/deep/.el-input-group__prepend {
  background: rgba(43, 164, 165, 0.6);
  color: rgb(243, 247, 250);
  font-weight: 600;
  font-size: 16px;
  border: none;
}

/deep/.el-table::before {
  height: 0px;
}

/deep/.el-table__empty-text {
  line-height: 560px;
  font-size: 16px;
  letter-spacing: 3px;
}

/deep/.el-tree-node__content:hover {
  background: rgb(48, 79, 145);
}

/deep/.el-table--scrollable-x .el-table__body-wrapper {
  scrollbar-width: none;
}

.btns {
  user-select: none;
  position: absolute;
  top: 10%;
  z-index: 9;
  right: 26%;

  span {
    display: inline-block;
    width: 30px;
    margin: 10px 5px;
    color: #1e7fee;
    font-size: 28px;
    cursor: pointer;
  }

  span:hover {
    color: #07d1db;
  }
}

.sort {
  // display: inline-block;
  width: 60px;
  cursor: pointer;
  padding: 10px;
  margin-left: 15px;
  border-radius: 5px;
  background: #1a6776;
}

.sort:hover {
  font-size: 16px;
}
</style>
